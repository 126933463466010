import AjaxException from "../models/AjaxException";
import AuthService, { GetAccessToken } from './auth.service';

export const WebApiUrl: string = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:3001/api/" : "api/";

export function GetAsync<TRequest, TResponse>(url: string, data: TRequest, useAccessToken: boolean = true): Promise<TResponse> {
    return AjaxCall(url, "GET", data, useAccessToken);
}

export function PostAsync<TRequest, TResponse>(url: string, data: TRequest, useAccessToken: boolean = true): Promise<TResponse> {
    return AjaxCall(url, "POST", data, useAccessToken);
}

function AjaxCall<TRequest, TResponse>(relativeUrl: string, method: string, data: TRequest, useAccessToken: boolean = true): Promise<TResponse> {
    let url = WebApiUrl + relativeUrl;
    return new Promise<TResponse>((resolve, reject) => {
        let headers: HeadersInit = {
            "Content-Type": "application/json"
        };
        if (useAccessToken === true) {
            let accessToken: string | null = GetAccessToken();
            if (accessToken == null) {
                AuthService.logout();
                return;
            }
            headers["Authorization"] = "Bearer " + accessToken;
        }
        let config: RequestInit = {
            method: method,
            headers: headers,
        }
        if (method === "POST") {
            config.body = JSON.stringify(data);
        } else if (method === "GET") {
            let requestdata: any = data;
            if (requestdata === undefined || requestdata == null) {
                (requestdata as any) = {};
            }
            (requestdata as any)["_"] = (new Date()).getTime();
            let dataKeys = Object.keys(requestdata);
            if (dataKeys.length > 0) {
                let query: string = "?";
                for (let i = 0; i < dataKeys.length; i++) {
                    if (requestdata[dataKeys[i]] === undefined || requestdata[dataKeys[i]] == null) return;
                    query += encodeURIComponent(dataKeys[i]) + "=" + encodeURIComponent(JSON.stringify(requestdata[dataKeys[i]]));
                }
                url = url + query;
            }
        }
        fetch(url, config).then(async (data) => {
            if (data.ok) {
                let responsejson: TResponse = (await data.json()) as TResponse;
                resolve(responsejson);
            } else {
                reject(new AjaxException(data));
            }
        }).catch((err) => {
            console.log(err);
            reject(new AjaxException(err));
        });
    });
}