export function GetDictionaryFromQuery(query: string): { [key: string]: string } {
    if (query === undefined || query == null || !(query.length > 0)) return {};
    let dictionary: { [key: string]: string } = {};

    let queries: string[] = query.split("&");
    for (let queryIndex = 0; queryIndex < queries.length; queryIndex++) {
        let queryCollection: string[] = queries[queryIndex].split("=");
        if (queryCollection.length !== 2 || queryCollection[0] === "" || queryCollection[0].length <= 0 || queryCollection[1] === "" || queryCollection[1].length <= 0) continue;
        dictionary[queryCollection[0]] = queryCollection[1];
    }
    return dictionary;
}