import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { CompoundButton } from '@fluentui/react/lib/Button';
import './Home.css';
import { GetAsync, PostAsync } from '../../services/ajax';
import { GetDictionaryFromQuery } from '../../services/functions';
import IWebApiDisplay from '../../interfaces/IWebApiDisplay';
import AuthService from "../../services/auth.service";
import IWebApiResponseMessage from '../../interfaces/IWebApiResponseMessage';

interface Props { }

interface State {
    displays?: IWebApiDisplay[]
}

export default class Home extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};

        this.doReloadMyDisplayList = this.doReloadMyDisplayList.bind(this);
        this.handleAddDisplay = this.handleAddDisplay.bind(this);
        this.handleOpenDisplayDetails = this.handleOpenDisplayDetails.bind(this);
        this.checkNetatmoAuth = this.checkNetatmoAuth.bind(this);
    }
    componentDidMount() {
        this.doReloadMyDisplayList();
        this.checkNetatmoAuth();
    }

    doReloadMyDisplayList() {
        let self = this;
        const user = AuthService.getCurrentUser();
        GetAsync<null, IWebApiDisplay[]>("displayhardwares/list?iduser=" + user.IdUser, null).then((data: IWebApiDisplay[]) => {
            self.setState({ displays: data });
        });
    }

    checkNetatmoAuth() {
        let query: string = window.location.search;
        if (query.length <= 0 || query.indexOf("?") <= -1) return;
        query = query.substring(query.indexOf("?") + 1);
        let queryDict: { [key: string]: string } = GetDictionaryFromQuery(query);
        let code: string = queryDict.code;
        let idDisplayHardware: number = (queryDict.state !== undefined && queryDict.state != null && queryDict.state.length > 0) ? parseInt(queryDict.state) : 0;
        PostAsync<{ "IdDisplayHardware": number, "Code": string }, IWebApiResponseMessage>("displayhardwares/netatmo/register", { "IdDisplayHardware": idDisplayHardware, "Code": code }).then((data: IWebApiResponseMessage) => {
            window.location.href = "#/display/" + idDisplayHardware;
        });
    }

    handleAddDisplay() {
        window.location.hash = "#/display/create";
    }

    handleOpenDisplayDetails(idDisplayHardware: number) {
        window.location.hash = "#/display/" + idDisplayHardware;
    }

    render() {
        return <>
            <Stack className={"contentbody"}>
                <Stack.Item>
                    <Label>Displays</Label>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                        <CompoundButton onClick={this.handleAddDisplay} iconProps={{ iconName: 'Add' }} secondaryText="Neues Display">
                            Hinzufügen
                        </CompoundButton>
                        {this.state.displays && this.state.displays.map((d, index) => {
                            return <CompoundButton iconProps={{ iconName: 'TVMonitor' }}
                                key={"cb" + index}
                                secondaryText={(d.DisplayHardwareType?.Height ?? "") + " x " + (d.DisplayHardwareType?.Width ?? "") + "px"}
                                onClick={() => { this.handleOpenDisplayDetails(d.IdDisplayHardware) }}
                            >
                                {d.Caption}
                            </CompoundButton>
                        })}
                    </Stack>
                </Stack.Item>
            </Stack>
        </>;
    }
}