import { PostAsync } from './ajax';
import EventBus from "../services/EventBus";

interface LoginRequest {
    Username: string;
    Password: string;
}

interface LoginResponse {
    Success: boolean;
    AccessToken: boolean;
    Message: string;
    IdUser: number
}

class AuthService {
    login(username: string, password: string) {
        return PostAsync<LoginRequest, LoginResponse>("auth/login", { Username: username, Password: password }, false)
            .then(response => {
                if (response.Success) {
                    localStorage.setItem("user", JSON.stringify(response));
                }
                return response;
            });
    }

    logout(sendEvent: boolean = true) {
        localStorage.removeItem("user");
        if (sendEvent)
            EventBus.dispatch("logout");
    }

    getCurrentUser() {
        const userStr = localStorage.getItem("user");
        if (userStr) return JSON.parse(userStr);

        return null;
    }
}

const authService = new AuthService();
export function GetAccessToken(): string | null {
    let user = authService.getCurrentUser();

    // TODO checken ob access token noch gültig
    if (user) {
        if (user.Expires > 0 && (new Date(user.Expires)) > (new Date())) {
            return user.AccessToken;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default authService;