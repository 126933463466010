export default class AjaxException {
    private name: string;
    private message: string;
    private fetchResponse: any;

    constructor(fetchResponse: any) {
        this.name = "";
        this.message = "";
        this.fetchResponse = fetchResponse;
    }
}