import React from 'react';
import IWebApiResponseMessage from '../../interfaces/IWebApiResponseMessage';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TextField } from '@fluentui/react/lib/TextField';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    PrimaryButton
} from '@fluentui/react';
import './DisplayCreate.css';
import { GetAsync, PostAsync } from '../../services/ajax';

interface Props { }

interface State {
    TypeOptions: IComboBoxOption[],
    SelectedIdDisplayHardwareType: number,
    SelectedCaption: string,
    isLoading: boolean
}

interface IDisplayHardwareType {
    IdDisplayHardwareType: number;
    Caption: string;
}

interface IDisplayHardware {
    IdDisplayHardwareType: number;
    Caption: string;
}

export default class DisplayCreate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            TypeOptions: [],
            SelectedCaption: "",
            SelectedIdDisplayHardwareType: 0,
            isLoading: false
        };
        this.loadDisplayHardwareTypes = this.loadDisplayHardwareTypes.bind(this);
        this.saveDisplayHardware = this.saveDisplayHardware.bind(this);
    }

    componentDidMount() {
        this.loadDisplayHardwareTypes();
    }

    loadDisplayHardwareTypes() {
        let self = this;
        GetAsync<null, IDisplayHardwareType[]>("displayhardwaretypes/list", null).then((list) => {
            self.setState({
                TypeOptions: list.map((item) => { return { key: item.IdDisplayHardwareType, text: item.Caption } }),
                SelectedIdDisplayHardwareType: (list.length > 0) ? list[0].IdDisplayHardwareType : 0
            })
        });
    }

    saveDisplayHardware() {
        this.setState({ isLoading: true });

        let body: IDisplayHardware = {
            Caption: this.state.SelectedCaption,
            IdDisplayHardwareType: this.state.SelectedIdDisplayHardwareType
        };
        PostAsync<IDisplayHardware, IWebApiResponseMessage>("displayhardwares/save", body).then((response: IWebApiResponseMessage) => {
            if (response.Success) {
                window.location.hash = "#/displayhardware/" + response.ID;
            } else {
                // TODO message anzeigen
            }
        });
    }

    render() {
        return <>
            <Stack>
                <Stack.Item>
                    <h2>Create Displays</h2>
                </Stack.Item>
                <Stack className={"formContainer"}>
                    <TextField label="Bezeichnung" required={true} value={this.state.SelectedCaption}
                        onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                            this.setState({ SelectedCaption: newValue ?? "" })
                        }} />
                    <ComboBox
                        label="Type"
                        required={true}
                        selectedKey={this.state.SelectedIdDisplayHardwareType}
                        options={this.state.TypeOptions}
                        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
                            if (option === undefined) return;
                            let typevalue: number = 0;
                            if (typeof option!.key === "string") {
                                typevalue = parseInt(option!.key as string);
                            } else {
                                typevalue = option!.key;
                            }
                            this.setState({ SelectedIdDisplayHardwareType: typevalue })
                        }}
                    />
                    <Stack horizontal={true} style={{ marginTop: "10px" }} tokens={{ childrenGap: 10 }}>
                        <PrimaryButton text="Speichern" onClick={this.saveDisplayHardware} disabled={this.state.isLoading} />
                        {(this.state.isLoading) && <Spinner size={SpinnerSize.small} />}
                    </Stack>
                </Stack>
            </Stack>
        </>;
    }
}