import React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import AuthService from '../../services/auth.service';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {
    Stack,
    MessageBar,
    MessageBarType
} from '@fluentui/react';

import './Login.css';

interface Props {

}

interface State {
    message: string;
    loading: boolean;
}

export default class Login extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            message: "",
            loading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!event.currentTarget.username.value || !(event.currentTarget.username.value.length > 0) || !event.currentTarget.password.value || !(event.currentTarget.password.value.length > 0)) {
            this.setState({
                message: "Sie müssen einen Benutzernaen und asswort eingeben.",
                loading: false
            });
            return false;
        }

        this.setState({
            message: "",
            loading: true
        });


        AuthService.login(event.currentTarget.username.value, event.currentTarget.password.value).then(
            () => {
                window.location.hash = "#/";
                window.location.reload();
            },
            error => {
                this.setState({
                    loading: false,
                    message: "Error occured"
                });
                if (error.fetchResponse) {
                    error.fetchResponse.json().then((json: any) => {
                        this.setState({
                            loading: false,
                            message: json.Message
                        });
                    })
                }
            }
        );
        return false;
    }

    render() {
        return (
            <Stack horizontalAlign="center" style={{ marginTop: "20px" }}>
                <div className={"loginContainer"}>
                    <form onSubmit={this.handleSubmit}>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <TextField
                                placeholder="Username"
                                name="username"
                            />
                            <TextField
                                type="password"
                                name="password"
                                placeholder={"Password"}
                                canRevealPassword
                                revealPasswordAriaLabel="Show password"
                            />
                            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
                                <PrimaryButton text="Login" type={"submit"} allowDisabledFocus={true} disabled={this.state.loading} />
                                {(this.state.loading) && <Spinner size={SpinnerSize.small} />}
                            </Stack>
                            {(this.state.message) && <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                                dismissButtonAriaLabel="Schließen"
                            >{this.state.message}
                            </MessageBar>}
                        </Stack>
                    </form>
                </div>
            </Stack>);
    }
}