import React, { RefObject, } from 'react';
import PageEditor from '../PageEditor/PageEditor';
import { Stack } from '@fluentui/react/lib/Stack';
import { useParams } from "react-router-dom";
import { RouterProps } from '../RouteComponentBase';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton, Modal, DetailsList, DetailsListLayoutMode, SelectionMode, TooltipHost, Icon } from '@fluentui/react';
import { GetAsync, PostAsync } from '../../services/ajax';
import IWebApiDisplayPage from '../../interfaces/IWebApiDisplayPage';
import IWebApiDisplay from '../../interfaces/IWebApiDisplay';
import IWebApiResponseMessage from '../../interfaces/IWebApiResponseMessage';
import { ShowAlert } from '../../services/Alert';
import { WebApiUrl } from '../../services/ajax';
import { GetAccessToken } from '../../services/auth.service';
import { Toggle } from '@fluentui/react/lib/Toggle';

interface Props extends RouterProps {

}

interface IDisplayHardware {
    IdDisplayHardwareType: number;
    Caption: string;
    Rotated: boolean;
}

interface IMenuDisplayPage extends IWebApiDisplayPage {
    Caption?: string
    PageKey?: string
}

interface State {
    currentIdDisplayHardware: number;
    displayHardware: IWebApiDisplay | null;
    currentMenuSelection: string;
    displayPagesList?: IMenuDisplayPage[];
    variables: { [key: string]: string }
    netatmoAuthUrl?: string;
    isNetatmoAuthenticated?: boolean;
    netatmoStationData?: any;
    isPreviewModalOpen: boolean;
    openWeatherForecastConfigured?: boolean,
    openWeatherForecastData?: any;
    openWeatherLocationQuery?: any;
    healthLogList?: any;

    //save/edit properties
    idDisplayPage: number;
    pageObjectCaption: string;
    defaultPageObjectConfigurationJson: string;
    pageObjectConfiguration: any;
}

const pageKey: string = "page";

export class Displays extends React.Component<Props, State> {
    pageEditorRef: RefObject<PageEditor> | undefined;
    constructor(props: Props) {
        super(props);

        this.state = {
            currentIdDisplayHardware: 0,
            currentMenuSelection: "headerdata",
            displayHardware: null,
            isPreviewModalOpen: false,
            variables: {},

            idDisplayPage: 0,
            pageObjectCaption: "",
            defaultPageObjectConfigurationJson: "",
            pageObjectConfiguration: {}
        }
        this.pageEditorRef = React.createRef<PageEditor>();

        this.onMenuClick = this.onMenuClick.bind(this);
        this.loadDisplayHardwareDetails = this.loadDisplayHardwareDetails.bind(this);
        this.loadDisplayPagesList = this.loadDisplayPagesList.bind(this);
        this.editPage = this.editPage.bind(this);
        this.doNetatmoSignIn = this.doNetatmoSignIn.bind(this);
    }

    componentDidMount() {
        let idDisplayHardware = parseInt(this.props.routerParameters?.id_display ?? "0");
        this.setState({ currentIdDisplayHardware: idDisplayHardware });
        this.loadDisplayHardwareDetails(idDisplayHardware);
        this.loadDisplayPagesList(idDisplayHardware);
        this.loadDefaultVariables(idDisplayHardware);
        this.loadNetatmoDetails(idDisplayHardware);
        this.loadOpenWeatherDetails(idDisplayHardware);
        this.loadHealthsLogs(idDisplayHardware);
    }

    shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        // die Ausnahmen hinzugefügt, damit das ändern der COnfiguration keine neu renderung verursacht
        if (nextState.currentMenuSelection === this.state.currentMenuSelection && JSON.stringify(nextState.pageObjectConfiguration) !== JSON.stringify(this.state.pageObjectConfiguration)) {
            return false;
        }
        return true;
    }

    loadDisplayHardwareDetails(idDisplayHardware: number) {
        let self = this;
        GetAsync<null, IWebApiDisplay>("displayhardwares/details/" + idDisplayHardware, null).then((data: IWebApiDisplay) => {
            self.setState({ displayHardware: data });
        });
    }

    loadNetatmoDetails(idDisplayHardware: number) {
        let self = this;
        GetAsync<null, { NetatmoAuthUrl: string, IsLoggedIn: boolean, StationsData: any }>("displayhardwares/netatmo/details/" + idDisplayHardware, null).then((data: { NetatmoAuthUrl: string, IsLoggedIn: boolean, StationsData: any }) => {
            self.setState({ netatmoAuthUrl: data.NetatmoAuthUrl, isNetatmoAuthenticated: data.IsLoggedIn, netatmoStationData: data.StationsData });
        });
    }

    loadOpenWeatherDetails(idDisplayHardware: number) {
        let self = this;
        GetAsync<null, { Configured: boolean, ForecastData: any }>("displayhardwares/openweather/details/" + idDisplayHardware, null).then((data: { Configured: boolean, ForecastData: any }) => {
            self.setState({ openWeatherForecastConfigured: data.Configured, openWeatherForecastData: data.ForecastData });
        });
    }

    loadDefaultVariables(idDisplayHardware: number) {
        let self = this;
        GetAsync<null, { [key: string]: string }>("displayhardwares/defaultvariables/" + idDisplayHardware, null).then((data: { [key: string]: string }) => {
            self.setState({ variables: data });
        });
    }

    loadDisplayPagesList(idDisplayHardware: number, preselectDisplayPage?: number) {
        let self = this;
        GetAsync<{ iddisplayhardware: number }, IWebApiDisplayPage[]>("displaypages/list/", { iddisplayhardware: idDisplayHardware }).then((data: IWebApiDisplayPage[]) => {
            let displayPages: IMenuDisplayPage[] = [];
            let tempDisplayPage: IMenuDisplayPage | null = null;
            let preselectedMenuDisplayPageObject: IMenuDisplayPage | null = null;
            if (data !== undefined && data != null && data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    tempDisplayPage = data[i];
                    tempDisplayPage.Caption = "Page " + i;
                    tempDisplayPage.PageKey = pageKey + "_" + i;
                    displayPages.push(tempDisplayPage);
                    if (preselectDisplayPage === tempDisplayPage.IdDisplayPage) {
                        preselectedMenuDisplayPageObject = tempDisplayPage;
                    }
                }
            }

            if (preselectedMenuDisplayPageObject != null) {
                self.setState({
                    displayPagesList: displayPages,
                    currentMenuSelection: preselectedMenuDisplayPageObject.PageKey!,
                    defaultPageObjectConfigurationJson: preselectedMenuDisplayPageObject.Configuration
                });
                this.pageEditorRef?.current?.reloadPageEditor();
            } else {
                self.setState({ displayPagesList: displayPages });
            }
        });
    }

    loadHealthsLogs(idDisplayHardware: number) {
        let self = this;
        GetAsync<{ iddisplayhardware: number }, any[]>("displayhardwarehealths/list", { iddisplayhardware: idDisplayHardware }).then((data: any[]) => {
            self.setState({ healthLogList: data });
        });
    }

    onMenuClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (item === undefined) return;

        let menuKey: string = item?.key ?? "";
        this.setState({ currentMenuSelection: menuKey });

        if (menuKey.startsWith(pageKey)) {
            if (menuKey === (pageKey + "_create")) {
                this.setState({
                    idDisplayPage: 0,
                    pageObjectCaption: "Create new page",
                    defaultPageObjectConfigurationJson: "",
                    pageObjectConfiguration: {}
                });
            } else if (this.state.displayPagesList !== undefined && this.state.displayPagesList != null && this.state.displayPagesList.length > 0) {
                for (var idp = 0; idp < this.state.displayPagesList.length; idp++) {
                    if (menuKey === this.state.displayPagesList![idp].PageKey!) {
                        this.setState({
                            idDisplayPage: this.state.displayPagesList![idp].IdDisplayPage,
                            pageObjectCaption: this.state.displayPagesList![idp].Caption ?? "",
                            defaultPageObjectConfigurationJson: this.state.displayPagesList![idp].Configuration,
                            pageObjectConfiguration: JSON.parse(this.state.displayPagesList![idp].Configuration)
                        });
                        break;
                    }
                }
            }
            this.pageEditorRef?.current?.reloadPageEditor();
        }
    }

    editPage() {
        if (this.state.currentIdDisplayHardware <= 0) return;

        let self = this;
        let obj: IWebApiDisplayPage = {
            IdDisplayHardware: this.state.currentIdDisplayHardware,
            IdDisplayPage: this.state.idDisplayPage,
            Configuration: JSON.stringify(this.pageEditorRef?.current?.getObjects())
        }

        PostAsync<IWebApiDisplayPage, IWebApiResponseMessage>("displaypages/save", obj).then((response: IWebApiResponseMessage) => {
            if (response.ID > 0) {
                self.loadDisplayPagesList(self.state.currentIdDisplayHardware, response.ID);
            } else {
                ShowAlert((response.Message != null && response.Message.length > 0) ? response.Message : "Error while saving display page.");
            }
        });
    }

    doNetatmoSignIn() {
        window.location.href = this.state.netatmoAuthUrl!;
    }

    doSetOpenWeatherLocation(searchLocation: string) {
        let req = {
            "IdDisplayHardware": this.state.currentIdDisplayHardware,
            "QueryLocation": searchLocation
        };
        PostAsync<any, IWebApiResponseMessage>("displayhardwares/openweather/configure", req).then((response: IWebApiResponseMessage) => {
            if (response.Success) {
                this.loadDefaultVariables(this.state.currentIdDisplayHardware);
                this.loadOpenWeatherDetails(this.state.currentIdDisplayHardware);
            } else {
                ShowAlert((response.Message != null && response.Message.length > 0) ? response.Message : "Error while saving open weather location.");
            }
        });
    }

    render() {
        let self = this;

        let pagesLinks: INavLink[] = [{ name: "Create Page", url: "", key: pageKey + "_create", icon: "Add" }];
        if (this.state.displayPagesList !== undefined && this.state.displayPagesList != null && this.state.displayPagesList.length > 0) {
            for (var idp = 0; idp < this.state.displayPagesList.length; idp++) {
                pagesLinks.push({ name: this.state.displayPagesList![idp].Caption!, url: "", key: this.state.displayPagesList![idp].PageKey!, icon: "FitPage" });
            }
        }

        let menuLinks: INavLink[] = [
            { name: 'Header data', url: "", key: 'headerdata', icon: 'Page' },
            { name: 'Netatmo', url: "", key: 'netatmo', icon: 'NetworkTower' },
            { name: 'Forecast', url: "", key: 'forecast', icon: 'CloudWeather' },
            { name: 'Health info', url: "", key: 'healths', icon: 'Diagnostic' },
            { name: 'Pages', url: "", isExpanded: true, links: pagesLinks }
        ];
        let menuNavigation: INavLinkGroup[] = [{ links: menuLinks }];

        let renderNetatmoDashboardValues: (dashboard: any) => JSX.Element = (dashboard: any) => {
            return (<div>
                {(dashboard.time_utc) && <div><b>TIME: </b> {(new Date(dashboard.time_utc * 1000)).toLocaleString()}</div>}
                {(dashboard.Temperature) && <div><b>TEMP: </b> {dashboard.Temperature}°C</div>}
                {(dashboard.CO2) && <div><b>CO2: </b> {dashboard.CO2}ppm</div>}
                {(dashboard.Humidity) && <div><b>Humidity: </b> {dashboard.Humidity}%</div>}
                {(dashboard.Noise) && <div><b>Noise: </b> {dashboard.Noise}dB</div>}
                {(dashboard.Pressure) && <div><b>Pressure: </b> {dashboard.Pressure}mbar</div>}
            </div>);
        };

        let renderOpenWeatherForecastDayValues: (forecastday: any) => JSX.Element = (forecastday: any) => {
            return (<div>
                {(forecastday.date) && <div><b>TIME: </b> {(new Date(forecastday.date * 1000)).toLocaleString()}</div>}
                {(forecastday.humidity) && <div><b>Humidity: </b> {forecastday.humidity}%</div>}
                {(forecastday.pressure) && <div><b>Pressure: </b> {forecastday.pressure}hPa</div>}
                {(forecastday.temp) && <div><b>TEMP: </b> {forecastday.temp}°C</div>}
                {(forecastday.temp_feels_like) && <div><b>TEMP FEELS: </b> {forecastday.temp_feels_like}°C</div>}
                {(forecastday.temp_max) && <div><b>TEMP MAX: </b> {forecastday.temp_max}°C</div>}
                {(forecastday.temp_min) && <div><b>TEMP MIN: </b> {forecastday.temp_min}°C</div>}

                {(forecastday.weathercaption) && <div><b>WEATHER: </b> {forecastday.weathercaption}</div>}
                {(forecastday.weatherdescription) && <div><b>WEATHER (description): </b> {forecastday.weatherdescription}</div>}

                {(forecastday.windspeed) && <div><b>Wind speed: </b> {forecastday.windspeed} meter/sec</div>}
                {(forecastday.winddeg) && <div><b>Wind degree: </b> {forecastday.winddeg} degrees</div>}
                {(forecastday.windgust) && <div><b>Wind gust: </b> {forecastday.windgust} meter/sec</div>}
            </div>)
        };

        return <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
            <Stack.Item>
                <Nav
                    onLinkClick={this.onMenuClick}
                    selectedKey={this.state.currentMenuSelection}
                    styles={{ root: { width: "300px" } }}
                    groups={menuNavigation}
                />
            </Stack.Item>
            <Stack.Item grow={true}>
                {(this.state.currentMenuSelection === "headerdata") &&
                    (<Stack className={"formContainer"} tokens={{ childrenGap: 10 }}>
                        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                            <Stack.Item align={"center"}>
                                <h2>Display</h2>
                            </Stack.Item>
                            <Stack.Item align={"center"}>
                                <PrimaryButton text="Save" iconProps={{ iconName: "Save" }} onClick={() => { }} />
                            </Stack.Item>
                        </Stack>

                        <TextField label="Caption"
                            key={"tfcaption" + this.state.displayHardware?.Caption.toLowerCase().replaceAll(" ", "")}
                            defaultValue={this.state.displayHardware?.Caption ?? ""} />
                        <Toggle label="Rotated"
                            key={"trotated" + this.state.displayHardware?.Caption.toLowerCase().replaceAll(" ", "")}
                            defaultChecked={this.state.displayHardware?.Rotated}
                            onText="90°" offText="0°" onChange={() => {

                            }} />
                    </Stack>)}
                {(this.state.currentMenuSelection === "netatmo") &&
                    (<Stack className={"formContainer"}>
                        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                            <Stack.Item align={"center"}>
                                <h2>Netatmo</h2>
                            </Stack.Item>
                            <Stack.Item align={"center"}>
                                {/*<PrimaryButton text="Save" iconProps={{ iconName: "Save" }} onClick={() => { }} />*/}
                            </Stack.Item>
                        </Stack>

                        {(!this.state.isNetatmoAuthenticated) && <PrimaryButton text='Sign in' iconProps={{ iconName: "SignOut" }} onClick={this.doNetatmoSignIn} />}
                        {(this.state.isNetatmoAuthenticated === true) && (
                            <div>
                                <div>
                                    <PrimaryButton text='Sign out' iconProps={{ iconName: "SignOut" }} onClick={() => { }} />
                                </div>
                                <div>
                                    <h3>MAIN</h3>
                                    {renderNetatmoDashboardValues(this.state.netatmoStationData.MainModule.DashboardData)}
                                    <h3>OUTDOOR</h3>
                                    {renderNetatmoDashboardValues(this.state.netatmoStationData.OutsideModule.DashboardData)}
                                    <h3>INDOOR</h3>
                                    {renderNetatmoDashboardValues(this.state.netatmoStationData.InsideModule.DashboardData)}
                                </div>
                            </div>
                        )}

                    </Stack>)}

                {(this.state.currentMenuSelection === "forecast") &&
                    (<Stack className={"formContainer"}>
                        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                            <Stack.Item align={"center"}>
                                <h2>Forecast</h2>
                            </Stack.Item>
                            <Stack.Item align={"center"}>
                                {/*<PrimaryButton text="Save" iconProps={{ iconName: "Save" }} onClick={() => { }} />*/}
                            </Stack.Item>
                        </Stack>


                        {(this.state.openWeatherForecastConfigured === true) && (
                            <div>
                                <div>
                                    <h3>Configuration</h3>
                                    <div>
                                        <TextField label="Location"
                                            key={"owcaption" + this.state.displayHardware?.Caption.toLowerCase().replaceAll(" ", "")}
                                            onChange={(e: any, newValue?: string | undefined) => {
                                                this.setState({ openWeatherLocationQuery: newValue })
                                            }}
                                        />
                                        <div>
                                            <PrimaryButton text='Search' iconProps={{ iconName: "Search" }} onClick={() => {
                                                this.doSetOpenWeatherLocation(this.state.openWeatherLocationQuery)
                                            }} />
                                        </div>
                                    </div>
                                    <div>Position: {this.state.openWeatherForecastData.Latitude}, {this.state.openWeatherForecastData.Longitude}</div>

                                    <h3>Next 6 hours</h3>
                                    {renderOpenWeatherForecastDayValues(this.state.openWeatherForecastData.NextSixHours)}

                                    <h3>Next 24 hours</h3>
                                    {renderOpenWeatherForecastDayValues(this.state.openWeatherForecastData.Next24Hours)}
                                </div>
                            </div>
                        )}

                    </Stack>)}

                {(this.state.currentMenuSelection === "healths") &&
                    (<Stack className={"formContainer"}>
                        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                            <Stack.Item align={"center"}>
                                <h2>Healths</h2>
                            </Stack.Item>
                            <Stack.Item align={"center"}>
                                {/*<PrimaryButton text="Save" iconProps={{ iconName: "Save" }} onClick={() => { }} />*/}
                            </Stack.Item>
                        </Stack>
                        <DetailsList
                            items={this.state.healthLogList.map((d: any) => {
                                return { "Timestamp": (new Date(d.Timestamp)).toLocaleString(), "Battery": d.Battery + "%" }
                            })}
                            compact={true}
                            columns={[
                                {
                                    key: 'column1',
                                    name: 'File Type',
                                    iconName: 'TVMonitor',
                                    isIconOnly: true,
                                    minWidth: 16,
                                    maxWidth: 16,
                                    onRender: (item: any) => (
                                        <TooltipHost>
                                            <Icon iconName="TVMonitor" />
                                        </TooltipHost>
                                    ),
                                },
                                {
                                    key: 'column2',
                                    name: 'Date',
                                    fieldName: 'Timestamp',
                                    minWidth: 90,
                                    maxWidth: 110,
                                    isPadded: true,
                                },
                                {
                                    key: 'column3',
                                    name: 'Battery',
                                    iconName: 'Database',
                                    isIconOnly: true,
                                    fieldName: 'Battery',
                                    minWidth: 16,
                                    maxWidth: 16,
                                }
                            ]}
                            selectionMode={SelectionMode.none}
                            getKey={(item: any, index?: number) => {
                                return "row" + index; // TODO
                            }}

                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                        />
                    </Stack>)}

                {(this.state.currentMenuSelection.startsWith(pageKey)) &&
                    (<Stack>
                        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                            <Stack.Item align={"center"}>
                                <h2>{this.state.pageObjectCaption}</h2>
                            </Stack.Item>
                            <Stack.Item align={"center"}>
                                <PrimaryButton text="Save" iconProps={{ iconName: "Save" }} onClick={() => { this.editPage(); }} />
                            </Stack.Item>
                            <Stack.Item align={"center"}>
                                <PrimaryButton text="Preview" iconProps={{ iconName: "TVMonitor" }} onClick={() => { this.setState({ isPreviewModalOpen: true }) }} />
                                <Modal
                                    isOpen={this.state.isPreviewModalOpen}
                                    onDismiss={(() => { this.setState({ isPreviewModalOpen: false }) })}
                                    isBlocking={false}
                                >
                                    <img src={WebApiUrl + "displaypages/preview/" + this.state.idDisplayPage + "?accesstoken=" + encodeURIComponent(GetAccessToken() ?? "")}
                                        width={(this.state.displayHardware?.DisplayHardwareType?.Width ?? 0) * 2}
                                        height={(this.state.displayHardware?.DisplayHardwareType?.Height ?? 0) * 2} />
                                </Modal>
                            </Stack.Item>
                        </Stack>
                        <PageEditor
                            ref={this.pageEditorRef}
                            default_objects_json={this.state.defaultPageObjectConfigurationJson}
                            variables={this.state.variables}
                            width={(this.state.displayHardware?.DisplayHardwareType?.Width ?? 0) * 2}
                            height={(this.state.displayHardware?.DisplayHardwareType?.Height ?? 0) * 2}
                            onChangeObjects={(objects: any) => {
                                self.setState({ pageObjectConfiguration: objects });
                            }}
                        />
                    </Stack>)}
            </Stack.Item>

        </Stack>;
    }
}

const WrappedComponent = (props: Props) => {
    const routerParameters = useParams()
    let addedprops: RouterProps = {};
    addedprops.routerParameters = routerParameters;
    return <Displays {...addedprops} />
}
export default WrappedComponent;