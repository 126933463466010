import React, { RefObject } from 'react';
import { fabric } from 'fabric';
import { Stack, ActivityItem, Toggle } from '@fluentui/react';
import { ColorPicker } from '@fluentui/react/lib/index';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption, IDropdown } from '@fluentui/react/lib/Dropdown';
import './PageEditor.css';
import { DefaultButton } from '@fluentui/react/lib/Button';
import PageEditorCanvas from './PageEditorCanvas';
import { Text, Rect } from 'fabric/fabric-impl';
import { TextField, ITextField } from '@fluentui/react/lib/TextField';
import { Icon } from '@fluentui/react/lib/Icon';
import { IconButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';

interface Props {
    default_objects_json: string;
    width: number;
    height: number;
    onChangeObjects: (objects: any) => void;
    variables: { [key: string]: string }
    //onSelectObjects: (objects_json: string) => void;
}

interface State {
    tbvBackgroundColor: boolean;
    tbvStrokeColor: boolean;
    currentObjects: fabric.Object[];
    currentSelectedObjects: fabric.Object[];
}

export default class PageEditor extends React.Component<Props, State> {
    pageEditorCanvasRef: RefObject<PageEditorCanvas> | undefined;
    buttonColorPickerRef: RefObject<DefaultButton>;
    fileSelectRef: RefObject<HTMLInputElement>;
    variableDropDownRef: RefObject<IDropdown>;
    textEditInputFieldRef: RefObject<ITextField>;
    idBackgroundColor?: string;
    idStrokeColor?: string;

    constructor(props: Props) {
        super(props);

        this.state = { tbvBackgroundColor: false, tbvStrokeColor: false, currentObjects: [], currentSelectedObjects: [] };
        this.pageEditorCanvasRef = React.createRef<PageEditorCanvas>();
        this.buttonColorPickerRef = React.createRef<DefaultButton>();
        this.fileSelectRef = React.createRef<HTMLInputElement>();
        this.variableDropDownRef = React.createRef<IDropdown>();
        this.textEditInputFieldRef = React.createRef<ITextField>();

        this.handleCanvasOnChangeObjects = this.handleCanvasOnChangeObjects.bind(this);
        this.handleCanvasOnSelectObjects = this.handleCanvasOnSelectObjects.bind(this);
        this.applyVariablesToString = this.applyVariablesToString.bind(this);

        this.onZoomIn = this.onZoomIn.bind(this);
        this.onZoomOut = this.onZoomOut.bind(this);

        this.onAddCircle = this.onAddCircle.bind(this);
        this.onAddRectangle = this.onAddRectangle.bind(this);
        this.onAddText = this.onAddText.bind(this);

        this.idBackgroundColor = "idBackgroundColor";
        this.idStrokeColor = "idStrokeColor";
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
    }

    reloadPageEditor() {
        this.pageEditorCanvasRef?.current?.reloadCanvas();
    }

    onZoomIn() { }
    onZoomOut() { }
    onAddCircle() {
        let rect = new fabric.Circle({ left: 200, top: 100, fill: "#000000", radius: 20 });
        this.pageEditorCanvasRef?.current?.add(rect);
    }
    onAddRectangle() {
        let rect = new fabric.Rect({ left: 200, top: 100, fill: "#000000", width: 20, height: 20, stroke: '#000000' });
        this.pageEditorCanvasRef?.current?.add(rect);
    }
    onAddText() {
        let t = new fabric.Text('TEXT', { fontFamily: 'Arial', left: 100, top: 100, fill: '#000000', fontSize: 18 });
        this.pageEditorCanvasRef?.current?.add(t);
    }

    onAddImage() {
        this.fileSelectRef.current?.click();
    }

    handleCanvasOnChangeObjects(currentObjects: fabric.Object[]) {
        console.log("handleCanvasOnChangeObjects");
        this.setState({ currentObjects: currentObjects, tbvBackgroundColor: false, tbvStrokeColor: false });
        if (this.props.onChangeObjects) {
            this.props.onChangeObjects(this.pageEditorCanvasRef?.current?.getObjects());
        }
    }

    getObjects(): fabric.Object[] {
        return this.pageEditorCanvasRef?.current?.getObjects() ?? [];
    }

    handleCanvasOnSelectObjects(currentSelectedObjects: fabric.Object[]) {
        this.setState({ currentSelectedObjects: currentSelectedObjects, tbvBackgroundColor: false, tbvStrokeColor: false });
    }

    applyVariablesToString(content: string): string {
        if (!content) return content;
        if (!this.props.variables) return content;
        let variableNames: string[] = Object.keys(this.props.variables);
        if (variableNames.length <= 0) return content;
        for (var i = 0; i < variableNames.length; i++) {
            content = content.replaceAll("{" + variableNames[i] + "}", this.props.variables[variableNames[i]]);
        }
        return content;
    }

    componentWillUnmount() {

    }

    render() {
        const commandBarItems: ICommandBarItemProps[] = [
            {
                key: 'newObject',
                text: "New",
                iconProps: { iconName: 'Add' },
                subMenuProps: {
                    items: [
                        { key: 'newCircle', text: 'Circle', onClick: () => { this.onAddCircle() }, iconProps: { iconName: 'StatusCircleRing' } },
                        { key: 'newRectangle', text: 'Rectangle', iconProps: { iconName: 'RectangleShape' }, onClick: () => { this.onAddRectangle() }, },
                        { key: 'newText', text: 'Text', iconProps: { iconName: 'InsertTextBox' }, onClick: () => { this.onAddText() } },
                        { key: 'newImage', text: 'Image', iconProps: { iconName: 'RectangleShape' }, onClick: () => { this.onAddImage() } }
                    ],
                },
            },
            {
                key: 'deleteSelected',
                text: "Delete",
                disabled: !(this.state.currentSelectedObjects && this.state.currentSelectedObjects.length > 0),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    this.pageEditorCanvasRef?.current?.remove.apply(this.pageEditorCanvasRef?.current, this.state.currentSelectedObjects);
                }
            },
            {
                key: 'duplicateSelected',
                text: "Duplicate",
                disabled: !(this.state.currentSelectedObjects && this.state.currentSelectedObjects.length > 0),
                iconProps: { iconName: 'Copy' },
                onClick: () => {
                    if (this.state.currentSelectedObjects.length <= 0) return;
                    let clonedObjects = this.state.currentSelectedObjects.map((obj: fabric.Object) => {
                        obj.clone((clonedObj: fabric.Object) => {
                            clonedObj.top = (clonedObj.top ?? 0) + 10;
                            clonedObj.left = (clonedObj.left ?? 0) + 10;
                            this.pageEditorCanvasRef?.current?.add.apply(this.pageEditorCanvasRef?.current, [clonedObj]);
                        }, ["data"]);
                    });

                }
            }
        ]

        let selectedObject: fabric.Object | null = null;
        if (this.state.currentSelectedObjects && this.state.currentSelectedObjects.length === 1) {
            selectedObject = this.state.currentSelectedObjects[0];
            setTimeout(() => {
                if (this.textEditInputFieldRef === undefined || this.textEditInputFieldRef == null) return;
                if (selectedObject === undefined || selectedObject == null) return;
                //this.textEditInputFieldRef.current!.value = selectedObject.data ?? "";
            }, 300);
        }

        return <Stack>
            <input ref={this.fileSelectRef} hidden={true} accept="image/png, image/gif, image/jpeg" type="file" onChange={() => {
                if ((this.fileSelectRef.current?.files?.length ?? 0) <= 0) {
                    return;
                }
                let self = this;
                const reader = new FileReader();
                reader.onload = () => {
                    fabric.Image.fromURL(reader.result as string, function (img) {
                        img.set({ left: 100, top: 100 });
                        self.pageEditorCanvasRef?.current?.add(img);
                    });
                };
                reader.readAsDataURL(this.fileSelectRef.current!.files![0]);
            }} />
            <Stack.Item>
                <CommandBar items={commandBarItems} />
            </Stack.Item>
            <Stack.Item grow={true}>
                <Stack horizontal={true} tokens={{ childrenGap: 10 }} className={"mainStack"}>
                    <Stack.Item>
                        <PageEditorCanvas ref={this.pageEditorCanvasRef}
                            default_objects_json={this.props.default_objects_json}
                            height={this.props.height}
                            width={this.props.width}
                            onChangeObjects={this.handleCanvasOnChangeObjects}
                            onSelectObjects={this.handleCanvasOnSelectObjects} />
                    </Stack.Item>
                    <Stack.Item grow={true}>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <DefaultButton id={this.idBackgroundColor} style={{ textAlign: "left" }}
                                disabled={(selectedObject == null)}
                                text={selectedObject && selectedObject.type === "text" ? "Text color" : "Background color"} iconProps={{ iconName: 'ColorSolid' }} onClick={() => {
                                    this.setState({ tbvBackgroundColor: !this.state.tbvBackgroundColor });
                                }} />
                            {(selectedObject && (selectedObject.type === "rect")) && (
                                <DefaultButton id={this.idStrokeColor} style={{ textAlign: "left" }}
                                    disabled={(selectedObject == null)}
                                    text={"Stroke color"} iconProps={{ iconName: 'ColorSolid' }} onClick={() => {
                                        this.setState({ tbvStrokeColor: !this.state.tbvStrokeColor });
                                    }} />
                            )}
                            {(selectedObject && selectedObject.type === "text") && (
                                <Stack tokens={{ childrenGap: 5 }}>
                                    <TextField
                                        componentRef={this.textEditInputFieldRef}
                                        multiline={true}
                                        key={"ta" + JSON.stringify((selectedObject as Text).data)}
                                        defaultValue={((selectedObject as Text).data)}
                                        label={"Text"}
                                        rows={5} onChange={(event, newValue) => {
                                            if (newValue === undefined) newValue = "";
                                            (selectedObject as Text).set("data", newValue);
                                            (selectedObject as Text).text = this.applyVariablesToString(newValue!);
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                        }} />
                                    <Stack horizontal={true}>
                                        <Stack.Item grow={true}>
                                            <Dropdown
                                                componentRef={this.variableDropDownRef}
                                                key={"ddddv" + JSON.stringify((selectedObject as Text).data)}
                                                defaultSelectedKey={(Object.keys(this.props.variables).length > 0) ? Object.keys(this.props.variables)[0] : null}
                                                options={Object.keys(this.props.variables).map((variable: string) => {
                                                    let o: IDropdownOption = { key: variable, text: variable };
                                                    return o;
                                                })}
                                            />
                                        </Stack.Item>
                                        <IconButton iconProps={{ iconName: "Add" }} onClick={() => {
                                            if ((this.variableDropDownRef.current?.selectedOptions.length ?? 0) > 0) {
                                                let dataValue: string = (selectedObject as Text).data;
                                                if (dataValue === undefined) dataValue = "";
                                                let newValue = dataValue + "{" + this.variableDropDownRef.current?.selectedOptions[0].key + "}";
                                                (selectedObject as Text).set("data", newValue);
                                                (selectedObject as Text).text = this.applyVariablesToString(newValue!);
                                                this.setState({ currentSelectedObjects: [selectedObject!] });
                                                this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                            }
                                        }} />
                                    </Stack>
                                    <Dropdown
                                        defaultSelectedKey={(selectedObject as Text)?.textAlign}
                                        key={"ddtxtalign" + JSON.stringify((selectedObject as Text).data)}
                                        label={"Text align"}
                                        options={["left", "center", "right"].map((s: string) => { let o: IDropdownOption = { key: s, text: s }; return o; })}
                                        onChange={(event, selectedOption) => {
                                            (selectedObject as Text).textAlign = selectedOption?.key as string;
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                        }} />
                                    <Dropdown
                                        defaultSelectedKey={(selectedObject as Text)?.fontFamily}
                                        key={"ddff" + JSON.stringify((selectedObject as Text).data)}
                                        label={"Font family"}
                                        options={["Arial", "Artial Black", "Comic Sans MS", "Courier New", "Georgia",
                                            "Impact", "Microsoft Sans Serif", "Tahoma",
                                            "Times New Roman", "Verdana", "Sarpanch", "Teko"].map((s: string) => { let o: IDropdownOption = { key: s, text: s }; return o; })}
                                        onChange={(event, selectedOption) => {

                                            (selectedObject as Text).fontFamily = selectedOption?.key as string;
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                        }} />
                                    <Dropdown
                                        defaultSelectedKey={(selectedObject as Text)?.fontWeight}
                                        key={"ddff2" + JSON.stringify((selectedObject as Text).data)}
                                        label={"Font weight"}
                                        options={["normal", "bold", "bolder", "100", "200",
                                            "300", "400", "500", "600", "700", "800", "900"].map((s: string) => { let o: IDropdownOption = { key: s, text: s }; return o; })}
                                        onChange={(event, selectedOption) => {

                                            (selectedObject as Text).fontWeight = selectedOption?.key as string;
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                        }} />
                                    <TextField
                                        type={"number"}
                                        label={"Font size"}
                                        key={"ddtf" + JSON.stringify((selectedObject as Text).data)}
                                        defaultValue={(selectedObject as Text).fontSize + ""}
                                        onChange={(e, newValue) => {
                                            if (newValue === undefined || newValue == null || isNaN(parseInt(newValue))) return;
                                            (selectedObject as Text).fontSize = parseInt(newValue);
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                        }}></TextField>
                                </Stack>
                            )}
                            {(selectedObject) && (
                                <Stack>
                                    <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                                        <Stack.Item grow={1}>
                                            <TextField
                                                type={"number"}
                                                label={"Position Left"}
                                                key={"tfposx" + JSON.stringify((selectedObject as fabric.Object).data)}
                                                defaultValue={(selectedObject as fabric.Object).left + ""}
                                                onChange={(e, newValue) => {
                                                    if (newValue === undefined || newValue == null || isNaN(parseInt(newValue))) return;
                                                    (selectedObject as fabric.Object).left = parseInt(newValue);
                                                    this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                                }}></TextField>
                                        </Stack.Item>
                                        <Stack.Item grow={1}>
                                            <TextField
                                                type={"number"}
                                                label={"Position Top"}
                                                key={"tfposy" + JSON.stringify((selectedObject as fabric.Object).data)}
                                                defaultValue={(selectedObject as fabric.Object).top + ""}
                                                onChange={(e, newValue) => {
                                                    if (newValue === undefined || newValue == null || isNaN(parseInt(newValue))) return;
                                                    (selectedObject as fabric.Object).top = parseInt(newValue);
                                                    this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                                }}></TextField>
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            )}
                            {(selectedObject && (selectedObject.type === "rect")) && (
                                <Stack>
                                    <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                                        <Stack.Item grow={1}>
                                            <TextField
                                                type={"number"}
                                                label={"Width"}
                                                key={"tfwidth" + JSON.stringify((selectedObject as fabric.Object).data)}
                                                defaultValue={(selectedObject as fabric.Object).width + ""}
                                                onChange={(e, newValue) => {
                                                    if (newValue === undefined || newValue == null || isNaN(parseInt(newValue))) return;
                                                    (selectedObject as fabric.Object).width = parseInt(newValue);
                                                    this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                                }}></TextField>
                                        </Stack.Item>
                                        <Stack.Item grow={1}>
                                            <TextField
                                                type={"number"}
                                                label={"Height"}
                                                key={"tfposy" + JSON.stringify((selectedObject as fabric.Object).data)}
                                                defaultValue={(selectedObject as fabric.Object).height + ""}
                                                onChange={(e, newValue) => {
                                                    if (newValue === undefined || newValue == null || isNaN(parseInt(newValue))) return;
                                                    (selectedObject as fabric.Object).height = parseInt(newValue);
                                                    this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                                }}></TextField>
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            )}

                            {(this.state.tbvBackgroundColor && selectedObject) && (
                                <TeachingBubble
                                    target={`#${this.idBackgroundColor}`}
                                    onDismiss={() => { this.setState({ tbvBackgroundColor: false }) }}
                                >
                                    <ColorPicker color={selectedObject.fill as string} alphaType={"none"}
                                        onChange={(ev, color) => {
                                            selectedObject!.set("fill", "#" + color.hex);
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                        }}
                                        showPreview={true} strings={{ hueAriaLabel: 'Hue' }}
                                    />
                                </TeachingBubble>
                            )}
                            {(this.state.tbvStrokeColor && selectedObject) && (
                                <TeachingBubble
                                    target={`#${this.idStrokeColor}`}
                                    onDismiss={() => { this.setState({ tbvBackgroundColor: false }) }}
                                >
                                    <ColorPicker color={selectedObject.stroke as string} alphaType={"none"}
                                        onChange={(ev, color) => {
                                            selectedObject!.set("stroke", "#" + color.hex);
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();
                                        }}
                                        showPreview={true} strings={{ hueAriaLabel: 'Hue' }}
                                    />
                                </TeachingBubble>
                            )}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <div className={"objectsContainer"}>
                            <Stack tokens={{ childrenGap: 5 }}>
                                {(this.state.currentObjects && this.state.currentObjects.length > 0) && this.state.currentObjects.map((obj, index) => {
                                    return <Stack key={"ai" + index} horizontal={true} verticalAlign='center' tokens={{ childrenGap: 5 }}>
                                        <div style={{
                                            width: "2px",
                                            height: "30px",
                                            backgroundColor: (this.state.currentSelectedObjects && this.state.currentSelectedObjects.indexOf(obj) > -1) ? "#106ebe" : "#FFFFFF"
                                        }}></div>

                                        <IconButton iconProps={{ iconName: "RedEye" }} checked={!(obj.visible === true)} onClick={() => {
                                            obj.visible = !(obj.visible === true);
                                            this.setState(this.state);
                                            this.pageEditorCanvasRef?.current?.reRenderCanvas();

                                        }} />
                                        <IconButton iconProps={{ iconName: "Edit" }} checked={obj.selectable !== true} onClick={() => {
                                            obj.selectable = !(obj.selectable === true);
                                            this.setState(this.state);

                                        }} />
                                        <Label>
                                            {obj.type?.toUpperCase() +
                                                (obj.type === "text" ? " - " + (obj as fabric.Text).text ?? "" : "") +
                                                " - (LEFT: " + Math.round(obj.left ?? 0) + " | TOP: " + Math.round(obj.top ?? 0) + ")"}
                                        </Label>
                                    </Stack>
                                })}
                            </Stack>
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack.Item >
        </Stack >;
    }
}