import React, { RefObject, LegacyRef } from 'react';
import { fabric } from 'fabric';
import { Spinner, SpinnerSize, ISpinner } from '@fluentui/react/lib/Spinner';

interface Props {
    default_objects_json: string;
    width: number;
    height: number;
    onChangeObjects: (objects: fabric.Object[]) => void;
    onSelectObjects: (objects: fabric.Object[]) => void;
}

interface State {

}

export default class PageEditorCanvas extends React.Component<Props, State> {
    canvasRef: RefObject<HTMLCanvasElement>;
    spinnerRef: RefObject<HTMLDivElement>;
    canvas: fabric.Canvas | null;

    componentDidUpdateCount: number;
    handleCanvasChangedCount: number;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.canvasRef = React.createRef<HTMLCanvasElement>();
        this.spinnerRef = React.createRef<HTMLDivElement>();
        this.canvas = null;
        this.handleCanvasChangedCount = 0;
        this.componentDidUpdateCount = 0;

        this.handleCanvasChanged = this.handleCanvasChanged.bind(this);
        this.reloadCanvas = this.reloadCanvas.bind(this);
        this.handleCanvasElementSelected = this.handleCanvasElementSelected.bind(this);
    }

    componentDidMount() {
        let self = this;
        var grid = 5;
        this.canvas = new fabric.Canvas(this.canvasRef.current);
        this.canvas.on('object:moving', (options) => {
            options?.target?.set({
                left: Math.round((options?.target?.left ?? 0) / grid) * grid,
                top: Math.round((options?.target?.top ?? 0) / grid) * grid
            });
        });

        this.canvas.on('mouse:up', () => {
            self.handleCanvasElementSelected();
        });

        this.canvas.on('object:added', () => {
            self.handleCanvasChanged();
        });

        this.canvas.on('object:modified', (options) => {
            self.handleCanvasChanged();
        });

        this.canvas.on('object:removed', (options) => {
            self.handleCanvasChanged();
        });

        this.reloadCanvas();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.componentDidUpdateCount < this.handleCanvasChangedCount) {
            return;
        }
        if (JSON.stringify(this.canvas?.toJSON(["data"])) === this.props.default_objects_json) {
            return;
        }
        this.componentDidUpdateCount = 0;
        this.handleCanvasChangedCount = 0;

        this.reloadCanvas();
    }

    reloadCanvas() {
        var self = this;
        self.canvas?.clear();

        if (self.spinnerRef.current !== undefined) self.spinnerRef.current!.style.display = "block";

        setTimeout(() => {
            if (self.spinnerRef.current !== undefined) self.spinnerRef.current!.style.display = "none";
            self.canvas?.loadFromJSON(self.props.default_objects_json, () => {
                self.reRenderCanvas();
            });
        }, 200);
    }

    reRenderCanvas() {
        this.canvas?.renderAll();
    }

    getObjects(): any {
        return this.canvas?.toObject(["data"]);
    }

    handleCanvasChanged() {
        this.handleCanvasChangedCount += 1;
        let currentObjects = this.canvas?.getObjects();
        if (currentObjects === undefined) {
            currentObjects = [];
        }
        if (this.props.onChangeObjects) {
            this.props.onChangeObjects(currentObjects);
        }
        this.handleCanvasElementSelected();
    }

    handleCanvasElementSelected() {
        if (this.props.onSelectObjects) {
            let currentSelectedObjects = this.canvas?.getActiveObjects();
            if (currentSelectedObjects === undefined) {
                currentSelectedObjects = [];
            }
            this.props.onSelectObjects(currentSelectedObjects);
        }
    }

    add(...objects: fabric.Object[]) {
        this.canvas?.add.apply(this.canvas, objects);
        objects.forEach((item: fabric.Object) => {
            this.canvas?.setActiveObject(item);
        });
    }

    remove(...objects: fabric.Object[]) {
        this.canvas?.remove.apply(this.canvas, objects);
    }

    render() {
        return <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "0px", left: "0px" }} ref={this.spinnerRef}><Spinner size={SpinnerSize.xSmall} hidden={true} /></div>
            <canvas className='pageeditorcanvas' ref={this.canvasRef}
                width={this.props.width}
                height={this.props.height} />
        </div>;
    }

    componentWillUnmount() {
        this.canvas?.off('object:moving');
        this.canvas?.off('object:added');
        this.canvas?.off('object:modified');
    }
}