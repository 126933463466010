import React from 'react';
import IWebApiResponseMessage from '../../interfaces/IWebApiResponseMessage';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TextField } from '@fluentui/react/lib/TextField';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    PrimaryButton
} from '@fluentui/react';
import './User.css';
import { GetAsync, PostAsync } from '../../services/ajax';
import authService from '../../services/auth.service';

interface Props { }

interface State {
    IdUser?: number;
    Username?: string;
    isLoading: boolean;
    Password?: string;
    Password2?: string;
}

export default class UserComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const user = authService.getCurrentUser();
        this.state = {
            isLoading: false,
            IdUser: user.IdUser
        };

        this.loadCurrentUser = this.loadCurrentUser.bind(this);
        this.saveCurrentUser = this.saveCurrentUser.bind(this);
    }

    componentDidMount() {
        this.loadCurrentUser();
    }

    loadCurrentUser() {
        let self = this;
        this.setState({ isLoading: true });
        GetAsync<null, any>("users/details/" + this.state.IdUser, null).then((currentUser) => {
            self.setState({
                isLoading: false,
                Username: currentUser.Username
            })
        });
    }

    saveCurrentUser() {
        let self = this;
        this.setState({ isLoading: true });

        let body: any = {
            IdUser: this.state.IdUser,
            Username: this.state.Username
        };
        if (this.state.Password && this.state.Password.length > 0) {
            body.Password = this.state.Password;
        }
        PostAsync<any, any>("users/save", body).then((response: IWebApiResponseMessage) => {
            if (response.Success) {
                self.setState({ isLoading: false, Password: "", Password2: "" });
            } else {
                // TODO message anzeigen
            }
        });
    }

    render() {
        return <>
            <Stack>
                <Stack.Item>
                    <h2>Benutzer</h2>
                </Stack.Item>
                <Stack className={"formContainer"}>
                    <TextField label="Bezeichnung" required={true} value={this.state.Username}
                        onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                            this.setState({ Username: newValue ?? "" })
                        }} />

                    <TextField label="Passwort" type="password" placeholder="(unverändert)" value={this.state.Password}
                        onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                            this.setState({ Password: newValue ?? "" });
                        }} />

                    <TextField label="Passwort (wiederholen)" type="password" placeholder="(unverändert)" value={this.state.Password2}
                        onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                            this.setState({ Password2: newValue ?? "" });
                        }} />

                    {(this.state.Password !== this.state.Password2) && <div>Passwort muss übereinstimmen!</div>}

                    <Stack horizontal={true} style={{ marginTop: "10px" }} tokens={{ childrenGap: 10 }}>
                        <PrimaryButton text="Speichern" onClick={this.saveCurrentUser} disabled={this.state.isLoading || (this.state.Password !== this.state.Password2)} />
                        {(this.state.isLoading) && <Spinner size={SpinnerSize.small} />}
                    </Stack>
                </Stack>
            </Stack>
        </>;
    }
}