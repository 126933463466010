import './App.css';
import React, { Component } from 'react';
import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom";
import { Stack } from '@fluentui/react/lib/Stack';
import { ActionButton } from '@fluentui/react/lib/Button';
import { ShowAlert } from './services/Alert';

import Home from './components/Home/Home';
import Display from './components/Display/Display';
import DisplayCreate from './components/DisplayCreate/DisplayCreate';
import Login from './components/Login/Login';
import User from './models/User';
import EventBus from "./services/EventBus";
import AuthService from "./services/auth.service";
import { MessageBar, MessageBarType } from '@fluentui/react';
import UserComponent from './components/User/User';

type Props = {}

type State = {
  currentUser: User | undefined,
  message?: string
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.handleShowAlert = this.handleShowAlert.bind(this);
    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
      //throw new Error();
    }
    window.onerror = function (event) {
      ShowAlert("fasd fjasdidjfof");
      console.log(event);
    }

    this.state = {
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    EventBus.on("logout", this.logOut);
    EventBus.on("showalert", this.handleShowAlert);
  }

  componentWillUnmount() {
    EventBus.remove("logout", this.logOut);
    EventBus.remove("showalert", this.handleShowAlert);
  }

  logOut() {
    AuthService.logout(false);
    this.setState({
      currentUser: undefined,
    });
  }

  openCurrentUser() {
    window.location.hash = "#/user/current/";
  }

  handleShowAlert(a: Event) {
    console.log(a);
    this.setState({ message: "afdas" });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <HashRouter>
        {(currentUser) && (<Stack className="menuContainer" horizontal={true} style={{ backgroundColor: "#464775" }} tokens={{ childrenGap: 10 }}>
          <Stack.Item grow={true} align={"center"}>
            <ActionButton iconProps={{ iconName: "TVMonitor" }} onClick={() => { window.location.href = "#/"; }}>
              Display Controller
            </ActionButton>

          </Stack.Item>
          <Stack.Item>
            <Stack>
              <span style={{ fontWeight: "600" }} onClick={this.openCurrentUser}>{currentUser.Username}</span>
              <a href="#/login" onClick={this.logOut}>Ausloggen</a>
            </Stack>
          </Stack.Item>
        </Stack>)}
        {(this.state.message &&
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
          >
            {this.state.message}
          </MessageBar>)}
        {(currentUser) && (<Stack className={"contentbody"}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/display/:id_display" element={<Display />} />
            <Route path="/display/:id_display/netatmo" element={<Display />} />
            <Route path="/display/create" element={<DisplayCreate />} />
            <Route path="/user/current" element={<UserComponent />} />
          </Routes>
        </Stack>)}
        <Routes>
          {(!currentUser) && <Route path="*" element={<Login />} />}
        </Routes>


        { /*<AuthVerify logOut={this.logOut}/> */}
      </HashRouter>
    );
  }
}

export default App;